<template>
  <div class="fullScreen show" v-loading="loading">
    <Crumbs>
      <el-breadcrumb-item slot="after"> 商务追踪 </el-breadcrumb-item>
    </Crumbs>
    <div class="mainList">
      <!-- <div class="mainList_operation clearfloat">
                <div class="mainList_operation_search">
                    <el-input placeholder="请输入内容" clearable v-model="condition.title" class="input-with-select">
                        <el-button slot="append" icon="el-icon-search" @click="pageChangeHandler(1)"></el-button>
                    </el-input>
                </div>
            </div> -->
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <!-- <div slot="header" class="clearfix">
                        <span>商务信息</span>
                    </div> -->
          <el-tabs v-model="activeName" class="fullScreenMainHeader">
            <el-tab-pane name="0" label="商务追踪"></el-tab-pane>
            <el-tab-pane name="1" label="基本信息" v-if="BusinessId"></el-tab-pane>
            <el-tab-pane name="2" label="附件" v-if="BusinessId"></el-tab-pane>
            <el-tab-pane name="3" label="商务团队分工" v-if="BusinessId"></el-tab-pane>
            <el-tab-pane name="4" label="商务绩效" v-if="BusinessId"></el-tab-pane>
          </el-tabs>

          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :row-style="rowStyle"
            @row-click="rowClick"
            :row-class-name="tableRowClassName"
            v-if="activeName == 0"
            highlight-current-row
            :default-expand-all="true"
            tooltip-effect="dark"
            height="80%"
            v-loading="loading"
          >
            <el-table-column align="center" type="index" label="序号"></el-table-column>
            <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
            <el-table-column align="center" prop="taskDate" label="时间">
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="content" label="内容"></el-table-column>
            <el-table-column align="center" prop="clientName" label="客户"></el-table-column>

            <el-table-column prop="title" align="center" width="" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  class="text_Edit_Bgc"
                  v-if="scope.row.status != 1"
                  @click.stop="new_edit(scope)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  class="text_Remove_Bgc"
                  v-if="scope.row.status != 1"
                  @click.stop="del(scope)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            v-if="activeName == 0"
            @current-change="pageChangeHandler"
            @size-change="handleSizeChange"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            :total="parameter.total"
          >
          </el-pagination>
          <Manage width="600px" :BusinessId="BusinessId" v-show="activeName == 1"></Manage>
          <Annex :manageId="BusinessId" :file_Data="file_Data" v-show="activeName == 2"></Annex>
          <Assign width="600px" :ManageId="BusinessId" v-show="activeName == 3"></Assign>
          <DistriBution
            width="600px"
            :options="{ businessManageId: BusinessId }"
            :Disabled="true"
            v-if="activeName == 4"
          ></DistriBution>

          <div class="fullScreenOperation" style="text-align: center; padding: 10px 0">
            <el-button size="primary" v-show="activeName == 0" @click="add()">新增</el-button>
            <el-button
              type="success"
              v-show="activeName == 0"
              :loading="submitLoading"
              @click="save"
              >保存</el-button
            >
            <el-button type="info" @click="retu">返回</el-button>
          </div>
        </div>
      </div>
    </div>

    <!-- 商务追踪 -->
    <EditDialog width="600px" :isShow.sync="showEditDialog" @submit="submit">
      <div slot="title" class="title">编辑商务追踪</div>
      <div slot="content" class="content">
        <el-form label-width="7em" :model="edit_form" ref="edit_form">
          <el-form-item label="报销费用" prop="cost" ref="cost">
            <el-input v-model.number="edit_form.cost" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="报销明细" prop="content" ref="content">
            <el-input type="textarea" v-model="edit_form.content" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="附件" prop="filePath" ref="filePath">
            <template>
              <template>
                <Upload
                  style="font-size: 24px; color: #399ffb; cursor: pointer"
                  :fileCodeParam="fileCode"
                  @upload="upload"
                ></Upload>
              </template>
              <template v-if="!file_Data.loading && file_Data.attachmentPath">
                <a
                  target="_blank"
                  style="text-decoration: revert; color: #409eff; padding-left: 5px"
                  :href="`${file_Data.attachmentPath}${file_Data.attachmentPath}`"
                  >{{ file_Data.attachmentPath }}</a
                >
              </template>
              <template v-if="file_Data.loading">
                <i class="el-icon-loading" style="color: #f00"></i>正在上传
              </template>
            </template>
          </el-form-item>
        </el-form>
      </div>
    </EditDialog>
    <!-- 弹出费用 -->

    <!-- 商务信息新增 -->
    <EditDialog width="600px" :isShow.sync="showAddDialog" @submit="add_submit">
      <div slot="title" class="title">添加商务追踪</div>
      <div slot="content" class="content">
        <el-form label-width="7em" :model="add_form" ref="add_form">
          <el-form-item label="姓名" prop="userName" ref="userName">
            <el-input v-model="add_form.userName" disabled placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="时间" prop="taskDate" ref="taskDate">
            <el-date-picker
              value-format="timestamp"
              v-model="add_form.taskDate"
              type="date"
              placeholder="请选择"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="客户" prop="clientName" ref="clientName">
            <el-autocomplete
              :fetch-suggestions="querySearch"
              v-model="add_form.clientName"
              @select="onInputSelect"
            >
            </el-autocomplete>
          </el-form-item>
          <el-form-item label="内容" prop="content" ref="content">
            <el-input type="textarea" v-model="add_form.content" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </EditDialog>
    <!-- 商务信息新增 -->
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Annex: () => import('./Annex.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Assign: () => import('@/views/publicView/Assign.vue'),
    Manage: () => import('@/views/publicView/Manage.vue'),
    DistriBution: () => import('@/views/publicView/DistriBution.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },
      condition: {
        title: '',
      },
      form: {},
      edit_form: {},
      tableData: [],
      tableData2: [],
      // forList:[],
      loading: false,
      showEditDialog: false,
      showAddDialog: false,
      submitLoading: false,
      type: '',
      fileCode: 'SWZJ',
      add_form: {},
      file_Data: {
        loading: false,
      },
      activeData: {},
      index: 0,
      activeName: 0,

      companyList: [],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  inject: ['retu'],
  props: {
    BusinessId: {},
  },
  watch: {
    BusinessId: {
      immediate: true,
      handler: function (a) {
        if (a) {
          this.getData(a)
        }
      },
    },
    activeData: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal) {
        if (newVal.hasOwnProperty('id') || newVal.hasOwnProperty('businessCost')) {
          this.tableData2 = Object.assign([], newVal.businessCost)
        } else {
          this.tableData2 = []
        }
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
  },
  created() {},
  methods: {
    getData() {
      this.loading = true
      this.$api.businessManage
        .getDisposeInfo(this.BusinessId)
        .then(res => {
          this.loading = false
          // this.tableData = res.data;
          let arr = []
          res.data.forEach(e => {
            e.status = 1
            arr.push(e)
          })

          if (this.tableData.length > 0 && this.BusinessId) {
            this.activeData = this.tableData[0].deepClone()
          } else {
            this.tableData2 = []
          }
          this.tableData = arr
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    upload(r) {
      r.some(res => {
        this.file_Data = res.combination({
          fileCode: this.fileCode,
        })
      })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    //添加商务追踪
    chansheng(scope) {
      this.edit_form = {}
      this.edit_form.is_add = true
      this.edit_form.businessTraceId = scope.row.id
      this.index = scope.$index
      this.file_Data = {}
      this.activeData = scope.row
      this.showEditDialog = true
    },
    //编辑商务追踪
    edit(scope) {
      this.edit_form = scope.row.deepClone()
      this.edit_form.tb_index = scope.$index
      this.edit_form.is_add = false
      this.file_Data.attachmentPath = scope.row.filePath

      this.showEditDialog = true
    },
    //编辑商务追踪
    new_edit(scope) {
      this.add_form = scope.row
      this.add_form.index = scope.$index
      this.add_form.is_edit = true

      this.showAddDialog = true
    },
    //新增商务信息
    add() {
      this.getPartnerCompany()
      this.add_form = {}
      this.add_form.is_edit = false
      this.add_form.userName = this.userInfo.userName
      this.showAddDialog = true
    },
    //提交新增，编辑商务追踪
    submit() {
      let edit_form = this.edit_form
      edit_form.businessId = this.BusinessId
      edit_form.filePath = this.file_Data.attachmentPath

      if (edit_form.is_add) {
        //新增
        if (this.tableData[this.index].hasOwnProperty('businessCost')) {
          //判断有无商务追踪
          this.tableData[this.index].businessCost.push(edit_form)
          this.activeData = this.tableData[this.index]
          this.tableData2 = this.tableData[this.index].businessCost
        } else {
          this.tableData[this.index].businessCost = []
          this.tableData[this.index].businessCost.push(edit_form)
          this.tableData2 = this.tableData[this.index].businessCost
        }
      } else {
        //编辑
        this.tableData[this.index].businessCost[edit_form.tb_index] = edit_form
        this.$set(this.tableData2, edit_form.tb_index, edit_form)
      }
      this.showEditDialog = false
    },
    //新增商务信息提交
    add_submit() {
      this.add_form.staffId = this.userInfo.staffId
      this.add_form.businessId = this.BusinessId

      if (this.add_form.is_edit) {
        this.tableData[this.add_form.index] = this.add_form
      } else {
        this.tableData.push(this.add_form)
      }
      this.showAddDialog = false
    },
    save() {
      this.submitLoading = true
      this.$api.businessManage
        .addBusinessTrace(this.tableData)
        .then(res => {
          this.submitLoading = false
          this.$message.success('保存成功！')
          this.retu()
        })
        .catch(err => {
          console.log(err)
          this.submitLoading = false
        })
    },
    rowStyle(res) {
      if (res.rowIndex == this.index) {
        return {
          background: '#f5f7fa',
        }
      } else {
        return {}
      }
    },
    rowClick(row) {
      this.activeData = Object.assign({}, row.deepClone())
      this.index = row.index
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    del(scope) {
      this.tableData.splice(scope.$index)
    },
    //获取业主单位数组
    getPartnerCompany() {
      this.$api.partnerCompany
        .getPartnerCompanyDataListNoPage({})
        .then(res => {
          if (res) {
            this.companyList = res.data.map(item => {
              return { value: item.name, id: item.id }
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 可筛选输入框回调
    querySearch(queryString, cb) {
      let companyList = this.companyList
      let results = queryString ? this.createFilter(queryString) : companyList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      let results = this.companyList
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(companyItem => companyItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },

    onInputSelect(e) {
      if (e.value) {
        this.add_form.clientName = e.value
        this.$refs.clientName.clearValidate()
      }
      this.$forceUpdate()
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.annex {
  display: flex;
  justify-content: space-between;
  /deep/.el-card {
    width: 50%;
    margin: 0 10px;
    .el-card__header {
      padding: 10px 15px;
      font-size: 16px;
      font-weight: bold;
    }
    .el-table {
      height: calc(100vh - 347px);
    }
  }
}
</style>
